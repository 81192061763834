/**
 * (c) OneGreenDiary Software Pvt. Ltd. 
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be 
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform, 
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive 
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Onces OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without 
 *    prior agreement with OneGreenDiary Software Pvt. Ltd. 
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ORDER_DATA } from '../DB/cart-items';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

	private auth_token: string;
	private user_email: string;
	public consumer_name: any;
	public shop_name: any;
	public isLoggedIn: boolean = false;
	//API header parameters
	public static DEFAULT_TOKEN = '00000000000000000000000000000000';

	/*order status*/
	public static ORDER_ON_HOLD = 'ORDS0001';
    public static ORDER_PENDING = 'ORDS0005';
    public static ORDER_NEW = 'ORDS0007';
    public static ORDER_COMPLETED = 'ORDS0008';
    public static ORDER_APPROVED = 'ORDS0002';
    public static ORDER_DELIVERED = 'ORDS0004';
    public static ORDER_REJECTED = 'ORDS0003';
    public static ORDER_CANCELED = 'ORDS0003';
    public static ORDER_EDITED = 'ORDS0009';
    public static ORDER_DRAFT = 'ORDS0010';
    public static ORDER_PICKEDUP = 'ORDS0011';
    public static ORDER_ASSIGNED_TO_RIDER = 'ORDS0012';
    public static ORDER_READY = 'ORDS0013';

    // payment and delivery types
    // delivery (shipping) type
    public static HOME_DELIVERY = 'ST0002';
    public static PICK_UP = 'ST0001';
    public static IMMEDIATE = 'ST0003';


	// follow a singleton pattern
	private static _instance: NavbarService = null;

	constructor(private http: HttpClient) { }

	// singleton helper
	public static getInstance(http: HttpClient): NavbarService {
		if (NavbarService._instance === null) {
			NavbarService._instance = new NavbarService(http);
			NavbarService._instance.setSessionData();
		}
		NavbarService._instance.http = http;
		return NavbarService._instance;
	}

	setUserData(userData: any) {
		this.auth_token = userData.auth_token;
		let userInfo = {
			user_mobile: userData.mobile_no,
			consumer_name: userData.consumer_name,			
			auth_token: this.auth_token
		}
		this.consumer_name = userData.consumer_name;
		localStorage.setItem('UMD', JSON.stringify(userInfo));		
	}

	setVerifiedFlag(flag) {
		localStorage.setItem('IS_VERIFIED', flag);
		if(flag) { 
			this.setSession();
			this.setSessionData();
		}
	}

	// set session related data
	setSession() {
		localStorage.setItem('session_time', (new Date()).toString());
	}

	setSessionData() {
		let userData:any = JSON.parse(localStorage.getItem('UMD'));
		let qrData:any = JSON.parse(localStorage.getItem('QRD'));
		let isVerified:any = localStorage.getItem('IS_VERIFIED');
		NavbarService._instance.auth_token = (userData) ? userData.auth_token : null;
		NavbarService._instance.consumer_name = (userData) ? userData.consumer_name : null;
		NavbarService._instance.shop_name = (qrData) ? qrData.shop_name : null;
		if(	qrData && qrData.shop_code &&
			NavbarService._instance.auth_token && (isVerified == true || isVerified == "true")) {
			NavbarService._instance.isLoggedIn = true;
		}
	}

	setQRCodeData(qrData) {
		let userInfo = {
			counter_number: qrData.counter_number,
			table_number: qrData.table_number,			
			section: qrData.section,			
			merchant_id: qrData.merchant_id,			
			shop_code: qrData.shop_code,			
			shop_name: qrData.name,			
		}
		NavbarService._instance.shop_name = (userInfo) ? userInfo.shop_name : null;		
		localStorage.setItem('QRD', JSON.stringify(userInfo));	
		localStorage.setItem('shop_code', qrData.shop_code);	
	}

	clearAllSessionData() {
		this.auth_token = null;
		localStorage.clear();
		NavbarService._instance.isLoggedIn = false;
		NavbarService._instance.consumer_name = "";
		NavbarService._instance.shop_name = "";
	}


	login(params: any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/register', 'method': 'POST' }, params);
	}

	verifyOTP(params: any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/verify', 'method': 'POST' }, params);
	}

	resendOTP(shop_code) {
		let params = {request_from:"msite"};
		if(shop_code) { params["shop_code"] = shop_code; };
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/resend', 'method': 'POST' }, params);		
	}	

	getShopItemsCategory(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/shop/shop_categories', 'method': 'POST' }, params);
	}		

	getShopItemsByCategoryCodes(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/shop/category_items_by_category_codes', 'method': 'POST' }, params);
	}

	getShopTaxDetail(params:any) {		
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/consumer/shop/tax_details/'+params.shop_code, 'method': 'GET' }, params);
	}

	verifyShipping(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/check_shipping', 'method': 'POST' }, params);		
	}

	placeOrder(params:any) {		
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/orders', 'method': 'POST' }, params);
	}

	savePaymentGatewayDetails(params:any) {		
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/payment/save_payment_gateway_details', 'method': 'POST' }, params);
	}

	makePayment(params:any) {		
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/make_payment', 'method': 'POST' }, params);
	}

	getOrderList(params:any) {		
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/orders', 'method': 'GET' }, params);
	}

	verifyQRCode(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/merchant/msite/access', 'method': 'POST' }, params);
	}

	getUserDiscountList(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/discount_list', 'method': 'GET' }, params);
	}

	applyDiscount(params:any) {
		return NavbarService.getInstance(this.http).apiCall({ 'url': '/user/apply_discount', 'method': 'POST' }, params);
	}
	 
	getHeaders(): HttpHeaders {
		if (!this.auth_token) {
			let userData = JSON.parse(localStorage.getItem('UMD'));
			if (userData && userData.auth_token) { this.auth_token = userData.auth_token; } else { this.auth_token = "" }
		}

		return new HttpHeaders({ 'Content-Type': "application/json", "auth_token": this.auth_token, "api_key":"12345" });
	}

	apiCall(apiKey: any, params: any): Observable<any> {
		let options: any = { headers: this.getHeaders() }
		let pUrl = environment[(apiKey["url_key"] ? apiKey["url_key"] : "url")];
		if (apiKey['method'] === 'POST') {
			return this.http.post(pUrl + apiKey['url'], JSON.stringify(params), options)
		} else if (apiKey['method'] === 'PUT') {
			return this.http.put(pUrl + apiKey['url'], JSON.stringify(params), options)
		} else if (apiKey['method'] === 'DELETE') {
			if (params) { options['body'] = JSON.stringify(params); }
			return this.http.delete(pUrl + apiKey['url'], options)
		} else {
			options.params = params;
			return this.http.get(pUrl + apiKey['url'], options)
		}
	}

	// check session validity based on time(in minutes)
	isSessionValid(validityMinutes: number = 120): boolean {
		var isVerified:any = localStorage.getItem("IS_VERIFIED");
		var shop_code:any = localStorage.getItem("shop_code");
		var sessionDate = new Date(localStorage.getItem('session_time')).getTime();
		var now = (new Date()).getTime();
		var timeDiff = Math.abs(sessionDate - now);
		timeDiff = Math.ceil(timeDiff / (1000 * 60));

		if (timeDiff > validityMinutes || !shop_code || !isVerified || isVerified == "false") {
			this.clearAllSessionData();
			return false;
		} else {
			return true;
		}
	}	
}
