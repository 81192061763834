import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'msite';
  hideNavbar: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        if(event.urlAfterRedirects && (event.urlAfterRedirects.indexOf("qrcode-scanner") != -1 || event.urlAfterRedirects.indexOf("qrcode-access") != -1)) {
          this.hideNavbar = true;
        } else {
          this.hideNavbar = false;
        }
      }     
      /*if(event instanceof NavigationStart) {
        console.log("s",event);       
      }  */    
    });
  }

}
