import { Injectable } from '@angular/core';
import { MainItem } from '../interfaces/main-item';
import { MainSubItem } from '../interfaces/main-sub-item';
import { ORDER_DATA } from '../DB/cart-items';
import { SharedService } from './shared.service';
import { DiscountService } from './discount.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart: MainItem[] = ORDER_DATA.CART_ITEMS;  
  private _discountService: DiscountService = new DiscountService();
  constructor() {}

  addItemToCart(item: MainItem): void {
    let isItemFound = false;
    for(var i=0,cLen=this.cart.length;i<cLen;i++) {
      if(
          this.cart[i].item_code == item.item_code && 
          ((!Array.isArray(item.options) || item.options.length == 0) && (!Array.isArray(this.cart[i].options) || this.cart[i].options.length == 0))
        ) {
        isItemFound = true;
        this.cart[i].item_count += item.item_count;
      }
    } 

    if(!isItemFound) {
      this.cart.push(item);
    }
    this.calculateOrderTotal();    
  }

  removeItemFromCart(item: MainItem,index:number): void {
    if(index == -1) {
      //for normal item
      this.cart.forEach((itm: MainItem, index: number) => {
        if(item.item_code === itm.item_code)
          this.cart.splice(index, 1);
      });
    } else {
      this.cart.splice(index, 1);
    }
    this.calculateOrderTotal();    
  }


  resetOrder(): void {
    ORDER_DATA["ORDER_TOTAL"] = 0;
    ORDER_DATA["ORDER_SUB_TOTAL"] = 0;
    ORDER_DATA["ORDER_TOTAL_ITEMS_COUNT"] = 0;
    ORDER_DATA["ORDER_TAX_DETAILS"] = [];
    ORDER_DATA["ORDER_TOTAL_TAX"] = 0;
    ORDER_DATA["CART_ITEMS"] = [];
    ORDER_DATA["NOTE"] = "";
    ORDER_DATA["SHIPPING_TYPE"] = SharedService["PICK_UP"];
    ORDER_DATA["EXTRA_CHARGES"] = [];
    ORDER_DATA["APPLIED_DISCOUNT"] = [];
    ORDER_DATA["MANUAL_APPLIED_DISCOUNT_DETAIL"] = [];
    ORDER_DATA["ORDER_TOTAL_DISCOUNT"] = 0;
    this.cart = ORDER_DATA["CART_ITEMS"];
  }

  resetDiscount(): void {
    ORDER_DATA["APPLIED_DISCOUNT"] = [];
    ORDER_DATA["MANUAL_APPLIED_DISCOUNT_DETAIL"] = [];
    ORDER_DATA["ORDER_TOTAL_DISCOUNT"] = 0;
  }

  calculateOrderTotal(): void  {
    let shopTaxInfo:any = localStorage.getItem("shop_tax") || null;
    if(shopTaxInfo) { shopTaxInfo = JSON.parse(shopTaxInfo); }

    let orderTotal:number = 0;
    let orderTotalDiscount:number = 0;
    let rounding_value:number = 0;
    let orderSubTotal:number = 0;
    let totalTax:number = 0;
    let totalItemsCount:number = 0;
    let tax_breakup_object: any = {};
    let calculated_extra_charges_list: any = [];
    let totalExtraCharge:number = 0;
    this.cart.forEach(item => {
      let itemPrice:number = 0;

      if(item.unit_price && item.item_count) {
        itemPrice = Number((item.item_count * item.unit_price));
        if(Array.isArray(item.options) && item.options.length>0) {
          item.options.forEach(op => {
            if(Array.isArray(op.variants) && op.variants.length>0) {
              op.variants.forEach(vItem => {
                if(vItem.selected) {
                  itemPrice += Number(vItem.unit_price);
                }
              });
            }
          })
        }
        orderSubTotal += itemPrice;
        totalItemsCount += Number(item.item_count);
        let itemTaxObj = this.getTaxOfItem(item,itemPrice,tax_breakup_object,shopTaxInfo);        
        item.tax_breakup = itemTaxObj;
        itemTaxObj.forEach(iTax => {
          if(tax_breakup_object[iTax.breakup_name]) {
            tax_breakup_object[iTax.breakup_name].value += iTax.value;
          } else {
            tax_breakup_object[iTax.breakup_name] = {
              breakup_name:iTax.breakup_name,
              value:iTax.value,
              rate:iTax.rate
            }
          }
        })
      } else if(Array.isArray(item.subItems) && item.subItems.length>0) {
        item.subItems.forEach((sItem:any) => {
          if(sItem.item_count > 0) {
            let tempSItemPrice:number = 0;
            tempSItemPrice += Number(sItem.item_count * sItem.unit_price)
            totalItemsCount += Number(sItem.item_count);
            if(Array.isArray(sItem.options) && sItem.options.length>0) {
              sItem.options.forEach(op => {
                if(Array.isArray(op.variants) && op.variants.length>0) {
                  op.variants.forEach(vItem => {
                    if(vItem.selected) {
                      tempSItemPrice += Number(vItem.unit_price);
                    }
                  });
                }
              })
            }
            let itemTaxObj= this.getTaxOfItem(sItem,tempSItemPrice,tax_breakup_object,shopTaxInfo);        
            sItem.tax_breakup = itemTaxObj;
            itemTaxObj.forEach(iTax => {
              if(tax_breakup_object[iTax.breakup_name]) {
                tax_breakup_object[iTax.breakup_name].value += iTax.value;
              } else {
                tax_breakup_object[iTax.breakup_name] = {
                  breakup_name:iTax.breakup_name,
                  value:iTax.value,
                  rate:iTax.rate,
                  type: iTax.type
                }
              }
            })

            itemPrice += tempSItemPrice;
          }
        })
        orderSubTotal += Number(itemPrice);
      }

      if(!shopTaxInfo.item_wise_tax && Array.isArray(shopTaxInfo.extra_tax_components) && 
          shopTaxInfo.extra_tax_components[0] && shopTaxInfo.extra_tax_components[0]["IND"]) {

        let tax = shopTaxInfo.extra_tax_components[0]["IND"];
        let itemTaxObj = [];

        if(tax && tax.breakup && !_.isEmpty(tax.breakup)) {
          for(var key in tax.breakup) {
            let tBrp = {
              breakup_name: key,
              type:tax.main_tax_name ? tax.main_tax_name : "main",                            
              rate: tax.breakup[key],
              value: (itemPrice) * (parseFloat(tax.breakup[key]) * 1) / 100
            };

            itemTaxObj.push(tBrp);

            if(tax_breakup_object[key]) {
              tax_breakup_object[key].value += tBrp.value;
            } else {

              tax_breakup_object[key] = {
                breakup_name:tBrp.breakup_name,
                type:tBrp.type,              
                value:tBrp.value,
                rate:tBrp.rate
              };
            }
          }
        } else if(tax.rate && tax.main_tax_name) {
            let tBrp = {
              breakup_name: tax.main_tax_name,
              type:tax.main_tax_name,              
              rate: tax.rate,
              value: (itemPrice) * (parseFloat(tax.rate) * 1) / 100
            };

            itemTaxObj.push(tBrp);
            itemTaxObj.forEach(iTax => {
              if(tax_breakup_object[iTax.breakup_name]) {
                tax_breakup_object[iTax.breakup_name].value += iTax.value;
              } else {
                tax_breakup_object[iTax.breakup_name] = {
                  breakup_name:iTax.breakup_name,
                  value:iTax.value,
                  rate:iTax.rate,
                  type:iTax.type
                }
              }
            });            
        }
        item.tax_breakup = itemTaxObj;
      }
    });
    let tempOrderTaxDetail = []
    for(var x in tax_breakup_object) {
      if(tax_breakup_object[x].value>0) {
        tempOrderTaxDetail.push({
          breakup_name:x,
          type:tax_breakup_object[x].type,
          rate:tax_breakup_object[x].rate,
          value: tax_breakup_object[x].value
        });
        totalTax += Number(tax_breakup_object[x].value);
      }
    }

    if(orderSubTotal>0 && shopTaxInfo && shopTaxInfo.enable_extra_charges && Array.isArray(shopTaxInfo.extra_charges)) {
        shopTaxInfo.extra_charges.forEach(ec => {

          if(!ec.shipping_types || (Array.isArray(ec.shipping_types) && ec.shipping_types.indexOf(ORDER_DATA["SHIPPING_TYPE"]) != -1)) {
            let ecValue = (ec.is_value_in_percentage) ? (orderSubTotal*ec.value)*0.01 : ec.value;
            totalExtraCharge += ecValue;

            calculated_extra_charges_list.push({
              charge_name: ec.charge_name,
              is_value_in_percentage: ec.is_value_in_percentage,
              value: ecValue
            });
          }
        });
    }

    ORDER_DATA.APPLIED_DISCOUNT = [];
    let appliedDiscountObj = this._discountService.getOrderDisountInfo(orderSubTotal,this.getOrderCartItems());   
    if(appliedDiscountObj && appliedDiscountObj.regular_discount && appliedDiscountObj.regular_discount.length>0) {
      appliedDiscountObj.regular_discount.forEach(di => {
        orderTotalDiscount += Number(di.value);
        ORDER_DATA.APPLIED_DISCOUNT.push(di);
      });
    }

    let tempOrderSubTotal:any = Number(orderSubTotal).toFixed(2);
    let tempOrderTotalTax:any = Number(totalTax).toFixed(2);
    let tempOrderTotalDiscount:any = Number(orderTotalDiscount).toFixed(2);
    orderTotal = Number(tempOrderSubTotal)+Number(tempOrderTotalTax)+Number(totalExtraCharge)-Number(tempOrderTotalDiscount);
    rounding_value = Number((Math.round(orderTotal) - orderTotal).toFixed(2));
    ORDER_DATA.ORDER_TOTAL = Math.round(orderTotal);
    ORDER_DATA.ROUNDING_VALUE = rounding_value;
    ORDER_DATA.ORDER_SUB_TOTAL = Number(tempOrderSubTotal);
    ORDER_DATA.ORDER_TAX_DETAILS = tempOrderTaxDetail;
    ORDER_DATA.ORDER_TOTAL_TAX = Number(tempOrderTotalTax);
    ORDER_DATA.ORDER_TOTAL_DISCOUNT = Number(tempOrderTotalDiscount);
    ORDER_DATA.ORDER_TOTAL_ITEMS_COUNT = Number(totalItemsCount);
    ORDER_DATA.EXTRA_CHARGES = calculated_extra_charges_list;
  }

  getOrderCartItems() {
    let order_cart_items = [];
    ORDER_DATA.CART_ITEMS.forEach(item => {
      if(item.unit_price && item.item_count) {
        let rItem:any = {
          item_code:item.item_code,
          item_name:item.item_name,  
          item_count:item.item_count,          
          unit:item.unit,
          item_price:Number(item.unit_price),
          note: item.note,
          attributes: [],          
          tax_breakup: item.tax_breakup
        };
        if(Array.isArray(item.options) && item.options.length>0) {
          item.options.forEach(op => {
            if(Array.isArray(op.variants) && op.variants.length>0) {
              op.variants.forEach((vItem:any) => {                
                if(vItem.selected) {
                  rItem.attributes.push({
                    attribute_code: vItem.attribute_code,
                    count: vItem.item_count ? vItem.item_count : item.item_count,
                    group_name: op.item_name,
                    name: vItem.item_name,
                    unit_price: parseFloat(vItem.unit_price),
                    price: parseFloat(vItem.unit_price)
                    
                  });
                }
              });
            }
          })
        }

        order_cart_items.push(rItem);
      } else if(Array.isArray(item.subItems) && item.subItems.length>0) {
        item.subItems.forEach((sItem:any) => {
          if(sItem.item_count > 0) {
            let rItem = {
              item_code:sItem.item_code,
              item_name:sItem.item_name,  
              item_count:sItem.item_count,
              description:sItem.description,
              unit:sItem.unit,
              item_price:parseFloat(sItem.unit_price),
              note: sItem.note,
              attributes: [],
              tax_breakup: sItem.tax_breakup    
            }
            if(Array.isArray(sItem.options) && sItem.options.length>0) {
              sItem.options.forEach(op => {
                if(Array.isArray(op.variants) && op.variants.length>0) {
                  op.variants.forEach(vItem => {
                    if(vItem.selected) {
                      rItem.attributes.push({
                        attribute_code: vItem.attribute_code,
                        count: vItem.item_count,
                        group_name: op.item_name,
                        name: vItem.item_name,
                        unit_price: parseFloat(vItem.unit_price),
                        price: parseFloat(vItem.unit_price)
                      });
                    }
                  });
                }
              })
            }

            order_cart_items.push(rItem);
          }
        })          
      }
    });

    return order_cart_items;
  }


  getTaxOfItem(item,itemPrice,tax_breakup_object,shopTaxInfo) {
    let itemTaxObj = [];    
    if(shopTaxInfo.item_wise_tax && Array.isArray(item.tax_settings) && item.tax_settings.length>0) {
      item.tax_settings.forEach(tax => {
        if(tax && tax.breakup && !_.isEmpty(tax.breakup)) {
          for(var key in tax.breakup) {
            let tBrp = {
              breakup_name: key,
              rate: tax.breakup[key],
              type: (tax.code) ? tax.code : (tax.main_tax_name) ? tax.main_tax_name :  "main",
              value: (itemPrice) * (parseFloat(tax.breakup[key]) * 1) / 100
            };

            if(tBrp.value > 0) { itemTaxObj.push(tBrp); }            
          }
        } else if(tax.rate && tax.main_tax_name) {
            let tBrp = {
              breakup_name: tax.main_tax_name,
              type: (tax.main_tax_name) ? tax.main_tax_name :  "main",              
              rate: tax.rate,
              value: (itemPrice) * (parseFloat(tax.rate) * 1) / 100
            };
            if(tBrp.value > 0) { itemTaxObj.push(tBrp); }
        }
      });      
    }

    return itemTaxObj;
  }

}
