import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quantity-element',
  templateUrl: './quantity-element.component.html',
  styleUrls: ['./quantity-element.component.scss']
})
export class QuantityElementComponent implements OnInit {

  @Input('customClasses') customClasses: string;
  @Input('quantity') quantity: number;

  @Output() changeEvent: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  increase(quantity: number, event: Event): void {
    quantity++;
    this.quantityChanged(quantity);
    event.stopPropagation();
  }

  decrease(quantity: number, event: Event): void {
    quantity--;
    this.quantityChanged(quantity);
    event.stopPropagation();
  }

  typing(quantity: number): void {
    if(quantity)
      this.quantityChanged(quantity);
  }

  quantityChanged(quantity: number): void {
    if(quantity <= 0)
      quantity = null;
    this.changeEvent.emit(quantity);
  }

}
