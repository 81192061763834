import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { HttpClient } from '@angular/common/http';
import { ToastMessageService } from '../../services/toast-message.service';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss']
})
export class QRCodeScannerComponent implements OnInit {
  
  @ViewChild('scanner',{static: false}) 
  scanner: ZXingScannerComponent;

  loading: boolean = false;  
  hasCameras = false;
  hasPermission: boolean;
  qrResultString: string;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;
  isStartScanClicked: boolean = false;
  scanCode: any = "";
  scannerEnabled: boolean = true;
  geolocationPosition: any;
  constructor(private router: Router,
              private http: HttpClient,
              private navbarService: NavbarService,
              private _toastMessageService: ToastMessageService,
              ) { }

  ngOnInit() {
    this.getLocationPermission();
    //this.router.navigate(['/qrcode-access'], { queryParams: { code: "6e7f233c49694b060a8ff6493ea7c304eafbd8611063017173d8dd20023fae0c80eda17e9d3ac83011bcf5ed9e7fd3370fb1d7e2f5e4aa41288e420b5a36fbe1c6b24b174f324c4a309e4df4bbd0b7a525c6db466f7e2e5df17998" } }); 
  }

  getLocationPermission() {
    if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
            position => {
                this.geolocationPosition = position;
            },
            error => {
                this._toastMessageService.alert('error','Location Permission needed.');
                /*switch (error.code) {
                    case 1:
                        this._toastMessageService.alert('error','Location Permission needed.');
                        break;
                    case 2:
                        this._toastMessageService.alert('error','Location Position Unavailable');
                        break;
                    case 3:
                        this._toastMessageService.alert('error','Timeout to give location permission');
                        break;
                    default: 
                        this._toastMessageService.alert('error','Timeout');
                        break;
                }*/
            }
        );
    };
  }

  startScaning(): void {
      if(!this.geolocationPosition) {
        this._toastMessageService.alert("error",'Please allow location permission.');        
        return;
      } else if(!this.isStartScanClicked) {
        this.isStartScanClicked = true;  
      } else if(!this.hasPermission){ 
        this._toastMessageService.alert("error",'Please allow camera to access and reload a page.');        
        return;
      } else if(!this.hasCameras) {
        this._toastMessageService.alert("error",'No camera found.');        
        return;
      }
      
      setTimeout(() => {
        this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
            this.hasCameras = true;
            this.availableDevices = devices;            
            
            for (const device of devices) {
                if (/back|rear|environment/gi.test(device.label)) {
                    this.selectedDevice = device;
                    break;
                }
            }

            if(!this.selectedDevice) {
              this.selectedDevice = devices[0];
            }
        });

        this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
          this._toastMessageService.alert("error",'An error has occurred when trying to enumerate your video-stream-enabled devices.');
        });

        this.scanner.permissionResponse.subscribe((answer: boolean) => {
          if(!answer) {
            this._toastMessageService.alert("error",'You need to allow camera to proceed');        
          }
          this.hasPermission = answer;
        });
      });
  }  

  handleQrCodeResult(resultString: string) {
    /*this.loading = true;*/
    if(resultString) {
      this.scannerEnabled = false;
      let splitData = resultString.split("?code=");
      if(splitData[1]) {
        this.router.navigate(['/qrcode-access'], { queryParams: { code: splitData[1] } });    
      } else {
        this._toastMessageService.alert('error','Wrong url.');
        this.isStartScanClicked = false;
      }      
    }
  }

  camerasNotFoundHandler(event) {    
    this._toastMessageService.alert("error",'No camera found');
  }  
}
