import { OrderData } from '../interfaces/order-data';
import { SharedService } from '../services/shared.service';

export const ORDER_DATA: OrderData = {
	ORDER_TOTAL: 0,
	ORDER_SUB_TOTAL: 0,
	ORDER_TOTAL_ITEMS_COUNT: 0,
	ORDER_TAX_DETAILS: [],
	ORDER_TOTAL_TAX:0,
	CART_ITEMS: [],
	NOTE:"",
	ROUNDING_VALUE:0,
	EXTRA_CHARGES:[],
	APPLIED_DISCOUNT:[],	
	MANUAL_APPLIED_DISCOUNT_DETAIL:[],
  	ORDER_TOTAL_DISCOUNT: 0,
  	PAYMENT_TYPE:SharedService.ONLINE_PAYMENT,	
};
