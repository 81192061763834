import { Component, OnInit, Input } from '@angular/core';
import { MainItem } from '../../interfaces/main-item';
import { MainSubItem } from '../../interfaces/main-sub-item';
import { MainSubItemOption } from '../../interfaces/main-sub-item-option';
import { MainSubItemOptionVariant } from '../../interfaces/main-sub-item-option-variant';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-main-item',
  templateUrl: './main-item.component.html',
  styleUrls: ['./main-item.component.scss']
})
export class MainItemComponent implements OnInit {

  @Input('item') item: MainItem;
  @Input('customClasses') customClasses: string;
  @Input('currencyCode') currencyCode: string;
  htmlTag = document.getElementsByTagName("html")[0];
  isVariantModalShow: boolean = false;
  selectedVariantItem: any;
  constructor(private _cartService: CartService) { }

  ngOnInit() {
  }

  getOptionInfo(subItem: MainSubItem): string {
    
    let optionInfo: string = '';
    
    subItem.options.forEach((option: MainSubItemOption) => {
      option.variants.forEach((variant: MainSubItemOptionVariant) => {
        if(variant.selected)
          optionInfo += option.item_name + '/' + variant.item_name + ', ';
      });
    });

    return optionInfo;
  }

  addItemToCart(item: MainItem): void {
    if(item.subItems && item.subItems.length > 0) {
      this.selectedVariantItem = JSON.parse(JSON.stringify(item));
      this.isVariantModalShow = true;      
      this.htmlTag.setAttribute("style","overflow:hidden");
      /*item.selected = !item.selected;
      this._cartService.calculateOrderTotal();      */
    } else if(!item.item_count) {
      item.selected = !item.selected;      
      item.item_count = 1;
      this._cartService.addItemToCart(item);
    }
  }

  itemQuantityChanged(item: MainItem, quantity: number) {
    item.item_count = quantity;

    if(!item.item_count) {
      this._cartService.removeItemFromCart(item,-1);
    } else {
      this._cartService.calculateOrderTotal();
    }
  }

  selectOptionVariant(option: MainSubItemOption, variant: MainSubItemOptionVariant,event) {    
    variant.selected = !variant.selected;
    variant.item_count = (variant.selected) ? 1 : 0;    
    this._cartService.calculateOrderTotal();
    event.stopPropagation();
  }  

  getItemPrice(subItem) {
    let itemPrice:number = 0;
    if(subItem.item_count > 0) {
      itemPrice += Number(subItem.item_count * subItem.unit_price)
      if(Array.isArray(subItem.options) && subItem.options.length>0) {
        subItem.options.forEach(op => {
          if(Array.isArray(op.variants) && op.variants.length>0) {
            op.variants.forEach(vItem => {
              if(vItem.selected) {
                itemPrice += Number(vItem.unit_price);
              }
            });
          }
        })
      }
    } else {
      itemPrice = Number(subItem.unit_price);
    }

    return itemPrice;    
  }
  
  closeVariantItemModal(event) {
    this.isVariantModalShow = false;
    this.htmlTag.setAttribute("style","overflow-x:hidden");
  }
}
