import { Injectable } from '@angular/core';

@Injectable()
export class StateGuardService {
  private states = {
    app: {
      currency: "$"
    }
  };
  constructor() { }

  public entrust (name, state) {
    this.states[name] = state;
  }
  obtain (name) {
    return this.states[name];
  }  
  clear() {
    this.states = {
      app: {
        currency: "$"
      }
    };
  }
  amend (name, pill) {
    pill(this.states[name]);
  }

}
