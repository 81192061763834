import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from 'ngx-loading';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthService } from './services/auth.service';
import { CartService } from './services/cart.service';
import { DiscountService } from './services/discount.service';
import { NavbarService } from './services/navbar.service';
import { ToastMessageService} from "./services/toast-message.service";
import { AuthGuard} from "./services/auth-guard.service";
import { StateGuardService} from "./services/state-guard.service";
import { MessageSubscriber} from "./services/message-subscriber.service";
import { SharedService } from './services/shared.service';

import { NavbarComponent } from './components/navbar/navbar.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { GetStartedComponent } from './views/get-started/get-started.component';
import { QRCodeScannerComponent } from './views/qrcode-scanner/qrcode-scanner.component';
import { QRCodeAccessComponent } from './views/qrcode-access/qrcode-access.component';
import { MainComponent } from './views/main/main.component';
import { OrderListComponent } from './views/order-list/order-list.component';
import { TopMenuItemsComponent } from './components/top-menu-items/top-menu-items.component';
import { MainItemComponent } from './components/main-item/main-item.component';
import { VariantItemComponent } from './components/variant-item/variant-item.component';
import { QuantityElementComponent } from './components/quantity-element/quantity-element.component';
import { CartComponent } from './views/cart/cart.component';
import { FreeDeliveryDealComponent } from './components/free-delivery-deal/free-delivery-deal.component';
import { ToastMessageComponent } from './components/toast-message/toast-message.component';

import { CapitalizeFirstPipe } from './pipes/capitalize-first.pipe';
import { BacktipsDatePipe } from './pipes/backtips-date.pipe';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SideMenuComponent,
    GetStartedComponent,
    QRCodeScannerComponent,
    QRCodeAccessComponent,
    MainComponent,
    TopMenuItemsComponent,
    MainItemComponent,
    VariantItemComponent,
    OrderListComponent,
    QuantityElementComponent,
    CartComponent,
    FreeDeliveryDealComponent,
    ToastMessageComponent,
    CapitalizeFirstPipe,
    BacktipsDatePipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxLoadingModule,
    ZXingScannerModule
  ],
  providers: [AuthService, CartService, DiscountService, NavbarService,SharedService,ToastMessageService,AuthGuard,StateGuardService,MessageSubscriber,CapitalizeFirstPipe,BacktipsDatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
