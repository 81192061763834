import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { QRCodeScannerComponent } from './views/qrcode-scanner/qrcode-scanner.component';
import { QRCodeAccessComponent } from './views/qrcode-access/qrcode-access.component';
import { GetStartedComponent } from './views/get-started/get-started.component';
import { MainComponent } from './views/main/main.component';
import { OrderListComponent } from './views/order-list/order-list.component';
import { CartComponent } from './views/cart/cart.component';
import { AuthGuard} from "./services/auth-guard.service";

const routes: Routes = [
  { path: 'login',canActivate: [AuthGuard], component: GetStartedComponent },
  { path: 'main',canActivate: [AuthGuard], component: MainComponent },
  { path: 'cart',canActivate: [AuthGuard], component: CartComponent },
  { path: 'order-list',canActivate: [AuthGuard], component: OrderListComponent },
  { path: 'qrcode-scanner',canActivate: [AuthGuard], component: QRCodeScannerComponent },
  { path: 'qrcode-access',canActivate: [AuthGuard], component: QRCodeAccessComponent },  
  { path: '',canActivate: [AuthGuard], redirectTo: '/qrcode-scanner', pathMatch: 'full' },
  { path: '*', redirectTo: '/qrcode-scanner', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
