import { Injectable } from '@angular/core';
import { Subject,Observable } from 'rxjs';
 
@Injectable()
export class MessageSubscriber {
    private menuSearchTextSubject = new Subject<any>();
 
    sendMenuSearchText(message: string) {
        this.menuSearchTextSubject.next({ search_text: message});
    }

    getMenuSearchText(): Observable<any> {
        return this.menuSearchTextSubject.asObservable();
    }
}