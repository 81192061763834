import { Injectable } from '@angular/core';
import { MainItem } from '../interfaces/main-item';
import { MainSubItem } from '../interfaces/main-sub-item';
import { ORDER_DATA } from '../DB/cart-items';
import { NavbarService } from './navbar.service';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  	cart: MainItem[] = ORDER_DATA.CART_ITEMS;  
  	constructor() { }

  	public getOrderDisountInfo(subtotal,cart_items) {
		let all_discount_list = JSON.parse(localStorage.getItem('shop_discounts')) || [];
		let discount_list = all_discount_list.filter(it => {
			var isExpired = true;
            if( it.discount_specifics.type  == 'regular_discount' && it.discount_specifics && it.discount_specifics.is_auto_apply) {
                //check coupon is not expired
            	if(it.discount_specifics.start_date && it.discount_specifics.end_date
                    && new Date(it.discount_specifics.start_date) <= new Date()
                    && new Date(it.discount_specifics.end_date) >= new Date()) {

                    //check coupon used in usage time period or not
                    if(it.discount_specifics.restrictions && Array.isArray(it.discount_specifics.restrictions.usage_time)) {
                        var usage_time = it.discount_specifics.restrictions.usage_time;
                        for(var i=0;i<usage_time.length;i++) {
                            if(usage_time[i] && usage_time[i].start_time && usage_time[i].end_time) {

                                var order_time = new Date().toTimeString().split(" ")[0].split(":");
                                var order_time_in_sec = (parseInt(order_time[0])*3600)+(parseInt(order_time[1])*60)+(parseInt(order_time[2]));
                                var temp_offer_start_time = usage_time[i].start_time.split(":");
                                var start_time_in_sec = (parseInt(temp_offer_start_time[0])*3600)+(parseInt(temp_offer_start_time[1])*60)+(parseInt(temp_offer_start_time[2]));
                                var temp_offer_end_time = usage_time[i].end_time.split(":");
                                var end_time_in_sec = (parseInt(temp_offer_end_time[0])*3600)+(parseInt(temp_offer_end_time[1])*60)+(parseInt(temp_offer_end_time[2]));
                                if(start_time_in_sec <= order_time_in_sec && end_time_in_sec >= order_time_in_sec) {
                                    isExpired = false;
                                    break;
                                }
                            }
                        }
                    } else {
                        isExpired = false;
                    }
                }
            }
			return !isExpired;
		});
		if(ORDER_DATA.MANUAL_APPLIED_DISCOUNT_DETAIL && ORDER_DATA.MANUAL_APPLIED_DISCOUNT_DETAIL.length > 0){
    		discount_list = discount_list.concat(ORDER_DATA.MANUAL_APPLIED_DISCOUNT_DETAIL);
    	}

		let appliedDiscount:any = {
			bogo_discount : [],
			bogo_any_discount : [],
			regular_discount : []
		}

    	let shipping_type = NavbarService.PICK_UP;
    	discount_list = discount_list.filter(dil => {

	    	return (dil.discount_specifics.restrictions.shipping_types == 'no' || (
		            Array.isArray(dil.discount_specifics.restrictions.shipping_types) && dil.discount_specifics.restrictions.shipping_types.indexOf(shipping_type) != -1))
		});

		var discount_usage_rank = {"bogo":1,"bogo_any":2,"flat_offer":3,"combo":4,"regular_discount":5,"cashback":6,'discount_on_order':7};
		discount_list = discount_list.sort((a,b) => { return discount_usage_rank[a.discount_specifics.type]-discount_usage_rank[b.discount_specifics.type] })
		discount_list.forEach((dil) => {

			var is_restrict = 0;
    		dil.applied_discount = 0;            
    		if(dil.discount_specifics.type == 'regular_discount' && dil.discount_specifics.restrictions) {
				var minimum_cart_value = ((dil.discount_specifics.restrictions.minimum_cart_value || dil.discount_specifics.restrictions.minimum_cart_value == 0) && dil.discount_specifics.restrictions.minimum_cart_value != "no")  ? dil.discount_specifics.restrictions.minimum_cart_value : 0;
				if(subtotal !=0 && subtotal >= minimum_cart_value){
					let is_found = false;
					let discount_val = 0;
					let is_max_limit = false;
					let name = dil.discount_code;

					if(dil.discount_specifics.amount_mode == 'percent'){
						discount_val = (subtotal * dil.discount_specifics.amount * 0.01);
						if(discount_val > dil.discount_specifics.restrictions.usage_value_per_order){
							discount_val = dil.discount_specifics.restrictions.usage_value_per_order;
							is_max_limit = true;
						}

					}else if(dil.discount_specifics.amount_mode == 'fixed'){
						discount_val = dil.discount_specifics.amount;
					}
					if(discount_val){
						if(dil.max_discount && discount_val > dil.max_discount) {
							discount_val = dil.max_discount;
							is_max_limit = true;
						};
						let f_data = appliedDiscount.regular_discount.filter(k => k.name == name);
						if(f_data.length == 0){
							let tempData = {
	                    		name : name,
	                    		type : 'regular_discount',
	                    		value : (discount_val),
	                    		is_external : dil.is_external ? dil.is_external : false,
	                    		id : dil.id,
	                    		is_max_discount_val : is_max_limit,
	                    		add_time: new Date(),
	                    		is_exclusive : dil.discount_specifics.is_exclusive? dil.discount_specifics.is_exclusive : false,
	                    	}
	                    	appliedDiscount.regular_discount.push(tempData);
						}else{
							appliedDiscount.regular_discount.forEach(d =>{
								d.value = (discount_val * (-1));
							})
						}
					}
				}
            }
		});

    	/*if(ORDER_DATA.APPLIED_DISCOUNT && ORDER_DATA.APPLIED_DISCOUNT.length > 0){
    		ORDER_DATA.APPLIED_DISCOUNT.forEach(di =>{
    			let fData = appliedDiscount.regular_discount.filter(it => (it.is_external && di.discount_code == it.name));
    			if(fData && fData.length > 0) {
					di['is_external'] = true;
    				ORDER_DATA.APPLIED_DISCOUNT.push(di);
    			}
    		});
    	}*/
    	
    	let exclusiveRegularDiscounts = [].concat(appliedDiscount.regular_discount,appliedDiscount.bogo_discount).filter(ed => ed.is_exclusive);
	    if(exclusiveRegularDiscounts.length != 0) {
	      let tempSortedDiscount = exclusiveRegularDiscounts.sort((a,b) => { return (a.add_time-b.add_time);})
	      let finalDiscount = tempSortedDiscount[0];
	      let tempDisc: any;
	      if(finalDiscount.type == 'bogo'){
	        tempDisc = {
	          bogo_discount : [finalDiscount],
	          regular_discount : [],
	          bogo_any_discount : []
	        }
	      } else if(finalDiscount.type == 'bogo_any'){
	        tempDisc = {
	          bogo_discount : [],
	          regular_discount : [],
	          bogo_any_discount : [finalDiscount]
	        }
	      }else{
	        tempDisc = {
	          bogo_discount : [],
	          regular_discount : [finalDiscount],
	          bogo_any_discount : []
	        }
	      }
	      appliedDiscount = JSON.parse(JSON.stringify(tempDisc));            
	    }
		return JSON.parse(JSON.stringify(appliedDiscount));    	
	}
}
