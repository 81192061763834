import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { ToastMessageService } from '../../services/toast-message.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import * as _ from 'lodash';
@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit  {  
  loading: boolean = false;
  shopInfo: any;
  currencyCode: any = "INR";

  orderList:any = [];
  orderDetails: any = [];
  shop_code: any = localStorage.getItem("shop_code");  
  constructor(private navbarService: NavbarService,
              private http: HttpClient,
              private _toastMessageService: ToastMessageService,    
              private router: Router) { }

  ngOnInit() {
    if (!this.shop_code || !NavbarService.getInstance(null).isSessionValid()) {
      this.router.navigateByUrl('/');
      return;
    }    

    this.loading = true;
    this.getOrderList().then(olResult =>{
      this.loading = false;
    });
  }

  getOrderList() {
    return new Promise((resolve,reject) => {    
      NavbarService.getInstance(this.http).getOrderList({shop_code:this.shop_code}).subscribe(res => {
        if(res && res.status == 0) {
          res.result.forEach(order => {
            order.payment_list = [];
            for(var x in order.split_payment_details) {
              order.payment_list.push({
                code:x,
                name:order.split_payment_details[x]["name"],
                amount:order.split_payment_details[x]["amount"],
              })
            }
            order.mf_order_status = this.getOrderStatus(order.order_status);
            order.mf_tax_breakup = [];
            if(Array.isArray(order.tax_breakup) && order.tax_breakup.length>0) {
              order.mf_tax_breakup = order.tax_breakup;
            } else {
              let tmpTaxObj = {};
              order.item.forEach(it => {
                if(Array.isArray(it.tax_breakup) && it.tax_breakup.length>0) {
                  it.tax_breakup.forEach(tb => {
                    if(tmpTaxObj[tb.breakup_name]) {
                      tmpTaxObj[tb.breakup_name].value += tb.value;
                    } else {
                      tmpTaxObj[tb.breakup_name] = tb;
                    }
                  })
                }
              });
              
              for(var x in tmpTaxObj) {
                order.mf_tax_breakup.push(tmpTaxObj[x]);
              }             
            }            
          });

          res.result = res.result.sort((a,b) => {
            let ta: any = new Date(a.createdAt);
            let tb: any = new Date(b.createdAt);
            return tb-ta;
          });
          this.orderList = res.result;          
        }
        return resolve(true);
      }, err => {            
        return resolve(false);        
      });
    });
  }

  getOrderStatus(code) {
    let order_status =  {
      name: "NEW",
      color:"black"
    }

    if(code == this.getTypeCode('ORDER_COMPLETED')) {
      order_status["name"] = "COMPLETED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_NEW')) {
      order_status["name"] = "NEW";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_APPROVED')) {
      order_status["name"] = "APPROVED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_DELIVERED')) {
      order_status["name"] = "DELIVERED";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_REJECTED')) {
      order_status["name"] = "REJECTED";
      order_status["color"] = "#ff4d00";
    } else if(code == this.getTypeCode('ORDER_EDITED')) {
      order_status["name"] = "EDITED";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_DRAFT')) {
      order_status["name"] = "DRAFT";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_READY')) {
      order_status["name"] = "READY TO PICKUP";
      order_status["color"] = "#2c93ff";
    } else if(code == this.getTypeCode('ORDER_PICKEDUP')) {
      order_status["name"] = "PICKD UP";
      order_status["color"] = "#63b12d";
    } else if(code == this.getTypeCode('ORDER_ASSIGNED_TO_RIDER')) {
      order_status["name"] = "HAND OVER TO RIDER";
      order_status["color"] = "#63b12d";
    }   

    return order_status;
  }

  getTypeCode(code) {
    return NavbarService[code];
  }

  getSelectedOrderInfo(order,index) {
    this.orderDetails[index] = order;
    order.selected = !order.selected;
  }

  getBeforeHours(order_date:any){
    if(!order_date) {
      return "";
    }
    let date1 = new Date().getTime();
    let date2 = new Date(order_date).getTime();
    let diff = (date1 - date2);
    let min:any =(diff / (60000));
    let hour:any = Math.floor((min) / 60);
    let sendVal = hour+' hours';
    if(min < 60){
      sendVal = Math.floor(min)+' minutes';
    }
    return sendVal;
  }
}
