import { Component, OnInit, Input,AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { CartService } from '../../services/cart.service';

/*declare var $:any;*/
@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit,AfterViewInit {
	
	@Input('type') type: string;
  consumer_name:any = "";  
	constructor(private router: Router,private _navbarService: NavbarService,private _cartService: CartService) { }

  	ngOnInit() {}  

    ngAfterViewInit() {
      let self = this;
      document.addEventListener('click', function (event:any) {        
        if (event.target.matches('#menu_wrapper_id')) return;
        if (event.target.matches('#side-menu-img')) return;
        event.preventDefault();
        self.hideMenuWrapper();
      }, false);
    }

    ngDoCheck() {
      this.consumer_name = NavbarService.getInstance(null).consumer_name;         
    }

    hideMenuWrapper() {      
      document.getElementById("menu_wrapper_id").className = "menu-side-nav";
    }
   
    redirectTo(page) {
      this.hideMenuWrapper();
      this.router.navigateByUrl(page);            
    } 

    currentRoute(routename:any) {
      return this.router.url == routename;
    }

    logout() {
      NavbarService.getInstance(null).clearAllSessionData();
      this._cartService.resetOrder(); 
      this.router.navigateByUrl('/');      
    }
}
