import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainItem } from '../../interfaces/main-item';
import { NavbarService } from '../../services/navbar.service';
import { ToastMessageService } from '../../services/toast-message.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ORDER_DATA } from '../../DB/cart-items';
import { MessageSubscriber } from "../../services/message-subscriber.service";

import * as _ from 'lodash';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit,AfterViewInit  {
  order_details = ORDER_DATA;  


  showCategories: boolean;
  categoriesList:any = [];
  categoriesWiseItems:any = [];
  filteredCategoriesWiseItems:any = [];
  selectedCategoryItemList:any = [];
  selectedCategory: any;
  shopTaxInfo: any;
  shopDiscountList: any;
  loading: boolean = false;
  shopInfo: any;
  currencyCode: any = "INR";
  shop_code: any = localStorage.getItem("shop_code");
  constructor(private navbarService: NavbarService,
              private http: HttpClient,
              private _messageSubscriber:MessageSubscriber,
              private _toastMessageService: ToastMessageService,    
              private router: Router) { }

  ngOnInit() {
    if (!this.shop_code || !NavbarService.getInstance(null).isSessionValid()) {
      this.router.navigateByUrl('/');
      return;
    }    

    this.loading=true;
    this.getShopItemsCategories().then(cR => {
      this.getShopTaxDetail().then(tR => {
        this.getShopDiscountList().then(dR => {        
          this.filteredCategoriesWiseItems = JSON.parse(JSON.stringify(this.categoriesWiseItems));
          if(this.filteredCategoriesWiseItems[0]) {
            this.onSelectCategory(this.filteredCategoriesWiseItems[0]);
          }        
          this.loading = false;
        });
      });
    });
    this.subscribeForMenuSearch();
  }

  ngAfterViewInit() {
    this.handleCategoryNameChangeOnScroll();    
  }

  subscribeForMenuSearch() {
    this._messageSubscriber.getMenuSearchText().subscribe(message => {
      if(message) {
        if(message.search_text) {
          message.search_text = message.search_text.toLowerCase();
          let tempFilteredData = JSON.parse(JSON.stringify(this.categoriesWiseItems));
          this.filteredCategoriesWiseItems = tempFilteredData.filter(category => {
            _.remove(category.item_list, it => { 
              return it.item_name.toLowerCase().indexOf(message.search_text) == -1 
            });

            return category.item_list.length>0;
          });
        } else {
          this.filteredCategoriesWiseItems = JSON.parse(JSON.stringify(this.categoriesWiseItems));
        }
      }
    })
  }

  handleCategoryNameChangeOnScroll() {
    let self = this;    
    window.addEventListener('scroll', function(e) {      
        for(var i=(self.categoriesWiseItems.length-1);i>=0;i--) {
          let category = self.categoriesWiseItems[i];
          let element = document.getElementById(category.category_code);
          if(element && element.offsetTop < (window.scrollY+(window.innerHeight/2))) {
            self.selectedCategory = category;
            break;
          }
        }
    });
  }

  getShopItemsCategories() {
    return new Promise((resolve,reject) => {
      this.categoriesList = [];
      this.selectedCategoryItemList = [];
      NavbarService.getInstance(this.http).getShopItemsCategory({shop_code:this.shop_code}).subscribe(res => {
        if(res && res.status == 0) {
          if(Array.isArray(res.result.categories) && res.result.categories.length>0) {
            this.shopInfo = res.result.shop_info;
            if(this.shopInfo) {
              if(this.shopInfo.country) {
                this.currencyCode = "INR"//this.shopInfo.country ;
              }
              localStorage.setItem("shop_info",JSON.stringify(this.shopInfo));
              localStorage.setItem("currency_code",this.currencyCode);
            }

            this.categoriesList = res.result.categories;            
            this.getShopItemsByCategoryCodes(this.categoriesList).then(iR => {
              return resolve(true);
            })
            .catch(error => {
              return resolve(true);
            });          
            
          }
        } else {        
          this._toastMessageService.alert("error","Internal server error while featching categories");
          return resolve(false);
        }
      }, err => {      
        this._toastMessageService.alert("error","Internal server error while featching categories");
        return resolve(false);
      });
    });
  }

  getShopItemsByCategoryCodes(categoryList) {
    return new Promise((resolve,reject) => {
      let category_codes = categoryList.map(cat => { return cat.category_code; });
      NavbarService.getInstance(this.http).getShopItemsByCategoryCodes({shop_code:this.shop_code,category_codes:category_codes}).subscribe(res => {
        if(res && res.status == 0 && Array.isArray(res.result.items) && res.result.items.length>0) {          
          categoryList.forEach(cat => {
            let tempConvertedItemList = [];
            let tempMatchedItems = res.result.items.filter(it => { return it.master_item_category_code == cat.category_code;});
            tempMatchedItems.forEach(item => {
              if(!item.attributes || item.attributes.length==0) {            
                tempConvertedItemList.push({
                  item_code:item.item_code,
                  item_name:item.item_name,
                  item_count:0,
                  quantity:item.quantity,
                  description:item.description,
                  item_img_url:environment.url+"/"+item.item_img_url,
                  tax_settings:item.tax_settings,
                  unit:item.unit,
                  unit_price:item.unit_price,              
                  selected: false,
                  sequence_number: item.sequence_number,
                });
              }
            });
            tempMatchedItems = tempMatchedItems.sort((a,b) => {
                a.sequence_number = (a.sequence_number) ? parseInt(a.sequence_number) : 1000;
                b.sequence_number = (b.sequence_number) ? parseInt(b.sequence_number) : 1000;
                return a.sequence_number-b.sequence_number;  
            });
            let collatedItems = this.collectVariantOrModifiersData(tempMatchedItems);
            tempConvertedItemList = tempConvertedItemList.concat(collatedItems);
            if(tempConvertedItemList.length>0) {
              tempConvertedItemList = tempConvertedItemList.sort((a,b) => {
                a.sequence_number = (a.sequence_number) ? parseInt(a.sequence_number) : 1000;
                b.sequence_number = (b.sequence_number) ? parseInt(b.sequence_number) : 1000;
                return a.sequence_number-b.sequence_number;  
              });
              this.categoriesWiseItems.push({
                category_name: cat.category_name,
                category_code: cat.category_code,
                category_sequence: cat.category_sequence ? cat.category_sequence : 1000,                
                item_list: tempConvertedItemList
              })
            }
          });
        }
        this.categoriesWiseItems = this.categoriesWiseItems.sort((a:any,b:any) => { 
          return (parseInt(a.category_sequence)-parseInt(b.category_sequence));
        })
        
        resolve(true);
      }, err => {      
        resolve(true);
      });
    });
  }

  collectVariantOrModifiersData(items):any {
    let collatedItems = [];
    let variantItems = _.groupBy(items.filter((itm: any) => itm.variant_tag !== null && itm.variant_tag !== ''),(itm: any) => itm.variant_tag);    
    let modifiersItems = items.filter((itm: any) => !itm.variant_tag && Array.isArray(itm.attributes) && itm.attributes.length > 0);    

    //only variant item filter
    for (var vi in variantItems) {
      var variantItem: any = {};
      variantItem['item_name'] = vi;
      variantItem['item_code'] = vi+"_"+(new Date().getTime());
      variantItem['category_code'] = variantItems[vi][0].category_code;      
      variantItem['variant_attributes'] = [];
      variantItem['modifier_attributes'] = [];      
      variantItem['subItems'] = [];
      variantItem['subItemsSelected'] = 0;   

      let tempUniqueAttr:any = _.uniqBy([].concat.apply([], variantItems[vi].map((itm: any) => itm.attributes)),
        (atr: any) =>  atr ? atr.attribute_code : null);
      let tempVariantAttribute = _.groupBy(tempUniqueAttr.filter((atr: any) => atr.is_variant_attribute), (grp: any) => grp.group_name);        
      for(var x in tempVariantAttribute) {
        tempVariantAttribute[x].sort((a,b) => {
          a.sequence_number = (a.sequence_number) ? parseInt(a.sequence_number) : 1000;
          b.sequence_number = (b.sequence_number) ? parseInt(b.sequence_number) : 1000;
          return a.sequence_number-b.sequence_number;  
        });
        variantItem['variant_attributes'].push({
          group_name: x,
          selected: 0,
          variants: tempVariantAttribute[x]
        });
      }      
      variantItems[vi].forEach(subVIItem => {
        var uniqueAttr: any = _.uniqBy([].concat.apply([], subVIItem.attributes),(atr: any) => atr.attribute_code);        
        let modifierOptions = [];
        let temp_modifier_attributes = _.groupBy(uniqueAttr.filter((atr: any) => !atr.is_variant_attribute), (grp: any) => grp.group_name);
        for(var x in temp_modifier_attributes) {
          let modifiers = [];
          temp_modifier_attributes[x].forEach(attr => {            
            modifiers.push({
                attribute_code: attr.attribute_code,
                item_name: attr.name,
                is_selection_exclusive: attr.is_selection_exclusive,
                unit_price: attr.unit_price,
                selected: false
            });            
          });
          modifierOptions.push({
            id: x,
            item_name: x,
            variants: modifiers
          })
        }
        let isMaxOneModifierAllowed = (subVIItem.minimum_unique_attribute_selection == 1 && subVIItem.minimum_unique_attribute_selection == 1) ? true : false;
        variantItem['subItems'].push({
          item_code:subVIItem.item_code,
          item_name:subVIItem.item_name.replace(vi,""),
          item_count:0,
          attributes:subVIItem.attributes,              
          quantity:subVIItem.quantity,
          description:subVIItem.description,
          item_img_url:environment.url+"/"+subVIItem.item_img_url,
          tax_settings:subVIItem.tax_settings,
          unit:subVIItem.unit,
          unit_price:subVIItem.unit_price,
          item_type:subVIItem.item_type,
          is_max_one_modifier_allowed: isMaxOneModifierAllowed,
          minimum_unique_attribute_selection:subVIItem.minimum_unique_attribute_selection,
          maximum_unique_attribute_selection:subVIItem.maximum_unique_attribute_selection,
          selected: false,
          options: modifierOptions
        });
      });
      if(variantItem.subItems && variantItem.subItems.length>0) {
        variantItem["description"] = variantItem.subItems[0].description;
        variantItem["item_img_url"] = variantItem.subItems[0].item_img_url;        
        variantItem["unit_price"] = variantItem.subItems[0].unit_price;        
        variantItem.subItems = variantItem.subItems.sort((a,b) => {
          a.sequence_number = (a.sequence_number) ? parseInt(a.sequence_number) : 1000;
          b.sequence_number = (b.sequence_number) ? parseInt(b.sequence_number) : 1000;
          return a.sequence_number-b.sequence_number;  
        });
      }
      collatedItems.push(variantItem);
    }

    //only modifiers items filter
    modifiersItems.forEach(mItem => {
      var uniqueAttr: any = _.uniqBy([].concat.apply([], mItem.attributes),(atr: any) => atr.attribute_code);  
      let temp_modifier_attributes = _.groupBy(uniqueAttr.filter((atr: any) => !atr.is_variant_attribute), (grp: any) => grp.group_name);
      let modifierOptions = [];
      for(var x in temp_modifier_attributes) {
        let modifiers = [];
        temp_modifier_attributes[x].forEach(attr => {            
          modifiers.push({
              attribute_code: attr.attribute_code,
              item_name: attr.name,
              is_selection_exclusive: attr.is_selection_exclusive,
              unit_price: attr.unit_price,
              selected: false
          });            
        });
        modifierOptions.push({
          id: x,
          item_name: x,
          variants: modifiers
        })
      }
      collatedItems.push({
        item_code:mItem.item_code,
        item_name:mItem.item_name.replace(vi,""),
        item_count:0,              
        quantity:mItem.quantity,
        description:mItem.description,
        item_img_url:environment.url+"/"+mItem.item_img_url,
        tax_settings:mItem.tax_settings,
        unit:mItem.unit,
        unit_price:mItem.unit_price,              
        selected: false,
        options: modifierOptions
      });
    })

    return collatedItems;    
  }

  onSelectCategory(category) {
    this.showCategories = false;
    this.selectedCategory = category;    
    let element = document.getElementById(category.category_code);
    let firstElement = document.getElementById(this.filteredCategoriesWiseItems[0].category_code);
    if(element && firstElement) { 
      window.scrollTo(0, (element.offsetTop-firstElement.offsetTop));
    }
    /*if(Array.isArray(this.categoriesWiseItems[category.category_code]) && this.categoriesWiseItems[category.category_code].length > 0) {
      this.selectedCategoryItemList = JSON.parse(JSON.stringify(this.categoriesWiseItems[category.category_code]));
    } else {
      this.loading = true;
      this.getShopItemsByCategoryCodes(category.category_code).then(cR => {
        this.loading = false;
      })
    }*/
  }

  getShopTaxDetail() {
    return new Promise((resolve,reject) => {    
      NavbarService.getInstance(this.http).getShopTaxDetail({shop_code:this.shop_code,request_from:"msite"}).subscribe(res => {
        if(res && res.status == 0) {
          this.shopTaxInfo = res.result;
          localStorage.setItem("shop_tax",JSON.stringify(res.result));
        }

        return resolve(true);
      }, err => {            
        return resolve(false);        
      });
    });
  }

  getShopDiscountList() {
    return new Promise((resolve,reject) => {    
      NavbarService.getInstance(this.http).getUserDiscountList({shop_code:this.shop_code}).subscribe(res => {
        if(res && res.status == 0) {
          this.shopDiscountList = res.result;
          localStorage.setItem("shop_discounts",JSON.stringify(res.result));
        }

        return resolve(true);
      }, err => {            
        return resolve(false);        
      });
    });
  }
}
