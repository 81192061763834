/**
 * (c) OneGreenDiary Software Pvt. Ltd. 
 * This file is a part of OneGreenDiary platform code base.
 *
 * This file is distributed under following terms:
 * 1) OneGreenDiary owns the OneGreenDiary platform, of which this file is a part.
 * 2) Any modifications to the base platform by OneGreenDiary is owned by OneGreenDiary and will be 
 *    non-exclusively used by OneGreenDiary Software Pvt. Ltd. for its clients and partners.
 * 3) Rights of any third-party customizations that do not alter the base platform, 
 *    solely reside with the third-party.  
 * 4) OneGreenDiary Software Pvt. Ltd. is free to  change the licences of the base platform to permissive 
 *    opensource licences (e.g. Apache/EPL/MIT/BSD) in future.
 * 5) Onces OneGreenDiary platform is delivered to third party, they are free to modify the code for their internal use.
 *    Any such modifications will be solely owned by the third party.
 * 6) The third party may not redistribute the OneGreenDiary platform code base in any form without 
 *    prior agreement with OneGreenDiary Software Pvt. Ltd. 
 * 7) Third party agrees to preserve the above notice for all the OneGreenDiary platform files.
 */

import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
    /*order status*/
	public static ORDER_ON_HOLD = 'ORDS0001';
    public static ORDER_PENDING = 'ORDS0005';
    public static ORDER_NEW = 'ORDS0007';
    public static ORDER_COMPLETED = 'ORDS0008';
    public static ORDER_APPROVED = 'ORDS0002';
    public static ORDER_DELIVERED = 'ORDS0004';
    public static ORDER_REJECTED = 'ORDS0003';
    public static ORDER_CANCELED = 'ORDS0003';
    public static ORDER_EDITED = 'ORDS0009';
    public static ORDER_DRAFT = 'ORDS0010';
    public static ORDER_PICKEDUP = 'ORDS0011';
    public static ORDER_ASSIGNED_TO_RIDER = 'ORDS0012';
    public static ORDER_READY = 'ORDS0013';

    // payment types
    public static ONLINE_PAYMENT = 'PAYT0008';
    public static COD_PAYMENT = 'PAYT0002';

    // delivery (shipping) type
    public static HOME_DELIVERY = 'ST0002';
    public static PICK_UP = 'ST0001';
    public static IMMEDIATE = 'ST0003';

    getShopAndUserDistanceInKM(userLat,userLon) {
        let shopLat:any = parseFloat(localStorage.getItem("shop_lat"));
        let shopLon:any = parseFloat(localStorage.getItem("shop_lon"));
        userLat = userLat ? userLat : localStorage.getItem("user_lat");
        userLon = userLon ? userLon : localStorage.getItem("user_lon");
        if(!shopLat || !shopLon || !userLat || !userLon) {
            return 0;
        }        

        return this.getDistanceBetweenLatLongInKM(shopLat,shopLon,userLat,userLon);
    }

    getDistanceBetweenLatLongInKM(sourceLat,sourceLng,destinationLat,destinationLng) {       
        if(!sourceLat || !sourceLng || !destinationLat || !destinationLng) {
            return 0;
        }

        sourceLat = parseFloat(sourceLat);
        sourceLng = parseFloat(sourceLng);
        destinationLat = parseFloat(destinationLat);
        destinationLng = parseFloat(destinationLng);

        let R = 6371;
        let sourceLatRadian = sourceLat * (Math.PI / 180);
        let destLonRadian = destinationLng * (Math.PI / 180);
        let latRadianDiff = (destinationLat - sourceLat) * (Math.PI / 180);
        let destLonRadianDiff = (destinationLng - sourceLng) * (Math.PI / 180);
        let a = (Math.sin(latRadianDiff / 2) * Math.sin(latRadianDiff / 2)) 
                 + ( Math.cos(sourceLatRadian) * Math.cos(destLonRadian) * Math.sin(destLonRadianDiff / 2) * Math.sin(destLonRadianDiff / 2));
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d = R * c;

        return (d>0 ? d : 0);
    }
}