import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MainItem } from '../../interfaces/main-item';
import { MainSubItem } from '../../interfaces/main-sub-item';
import { MainSubItemOption } from '../../interfaces/main-sub-item-option';
import { MainSubItemOptionVariant } from '../../interfaces/main-sub-item-option-variant';
import { CartService } from '../../services/cart.service';
import { ToastMessageService } from '../../services/toast-message.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-variant-item',
  templateUrl: './variant-item.component.html',
  styleUrls: ['./variant-item.component.scss']
})
export class VariantItemComponent implements OnInit {

  @Input('item') item: MainItem;  
  @Output() onModalClose: EventEmitter<any> = new EventEmitter();  
  constructor(private _cartService: CartService,
    private _toastMessageService: ToastMessageService) { }

  ngOnInit() {
  }

  closeVariantItemModal() {
    this.onModalClose.emit(true);
  }

  onSelectVariantAttr(variant,variantAttr) {
    variant.selected_attr = variantAttr;
    this.selectVariantItem();
  }

  selectVariantItem() {
    let selectedVariantAttributes = this.getSelectedVariantAttributes();
    var selectedAttributes = selectedVariantAttributes.map((itm: any) => itm.attribute_code);
    var selectedItem = this.item['subItems'].filter((itm: any) => {
      return _.difference(selectedAttributes, itm.attributes.filter((x: any) =>
        x.is_variant_attribute).map((x: any) => x.attribute_code)).length === 0;
    });
    this.item.selectedItem = (selectedItem && selectedItem.length == 1) ? selectedItem[0] : null;
  }

  getSelectedVariantAttributes() {
    let tempVA = [];
    this.item.variant_attributes.forEach(variant => {
      if(variant.selected_attr) {
        tempVA.push(variant.selected_attr);
      }
    })

    return tempVA;
  }

  onSelectVariantModifier(variantModifierOptions,variantModifier) {    
    if(this.item.selectedItem.is_max_one_modifier_allowed) {
      variantModifierOptions.variants.forEach((mod:any) => {
        mod.selected = false;
      });
    }
    variantModifier.selected = (this.item.selectedItem.is_max_one_modifier_allowed) ? true : !variantModifier.selected;        
  }


  getSelectedItemPrice() {
    let totalPrice = 0;
    if(this.item.selectedItem) {      
      totalPrice+= parseFloat(this.item.selectedItem.unit_price);
      if(this.item.selectedItem.options) {
        this.item.selectedItem.options.forEach((mOp:any) => {
          mOp.variants.forEach((va:any) => {
            if(va.selected) {
              totalPrice += parseFloat(va.unit_price);
            }
          });          
        });
      }
    }
    return totalPrice;
  }

  onAddItem() {
     if(!this.item.selectedItem) {
      return this._toastMessageService.alert("error","Please select mandatory options");
    }

    this.checkMinimumMaximumAttribteCondition(this.item.selectedItem).then(result => {
      if(!result) { return; }   
      this.item.selectedItem.item_count = 1;
      this._cartService.addItemToCart(this.item.selectedItem);
      this._cartService.calculateOrderTotal();   
      this.closeVariantItemModal();
    })
  }

  checkMinimumMaximumAttribteCondition(dish) {
    return new Promise((resolve,reject)=> {
        let temp_dish_v = dish;
        if(!temp_dish_v.minimum_unique_attribute_selection && !temp_dish_v.maximum_unique_attribute_selection) {
          return resolve(true);
        }

        var flag = false;
        var unique_attr = [];
        if(this.item.selectedItem.options) {
          this.item.selectedItem.options.forEach((mOp:any) => {
            mOp.variants.forEach((va:any) => {
              if(va.selected) {
                unique_attr.push(va.attribute_code);
              }
            });          
          });
        }
         
        if(temp_dish_v.minimum_unique_attribute_selection && unique_attr.length < temp_dish_v.minimum_unique_attribute_selection) {
          return this._toastMessageService.alert("error","select  minimum "+ temp_dish_v.minimum_unique_attribute_selection +" modifier");          
          return resolve(false);
        } else if(temp_dish_v.maximum_unique_attribute_selection && temp_dish_v.maximum_unique_attribute_selection > 0 && unique_attr.length > temp_dish_v.maximum_unique_attribute_selection) {
          return this._toastMessageService.alert("error", "you can select  maximum "+ temp_dish_v.maximum_unique_attribute_selection +" modifier");
          return resolve(false);
        } else {
          return resolve(true);
        }
    });
  }  
}
