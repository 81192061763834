import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { HttpClient } from '@angular/common/http';
import { ToastMessageService } from '../../services/toast-message.service';

@Component({
  selector: 'app-qrcode-access',
  templateUrl: './qrcode-access.component.html',
  styleUrls: ['./qrcode-access.component.scss']
})
export class QRCodeAccessComponent implements OnInit {
  loading: boolean = false;  
  hasCameras = false;
  hasPermission: boolean;
  qrResultString: string;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;
  isStartScanClicked: boolean = false;
  scanCode: any = "";
  scannerEnabled: boolean = true;
  geolocationPosition: any;
  constructor(private router: Router,
              private http: HttpClient,
              private navbarService: NavbarService,
              private route: ActivatedRoute,
              private _toastMessageService: ToastMessageService,
              ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
        if(params && params.code) {
          this.scanCode = params.code;
        }
    }); 

    if(!this.scanCode) {
      this._toastMessageService.alert('error','Wrong url.');
      this.router.navigate(['/qrcode-scanner']);
      return;
    }

    if(!NavbarService.getInstance(null).isSessionValid()) {
      //it will clear session if it's invalid.
    }

    this.getLocationPermission();
  }

  getLocationPermission() {
    if (window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
            position => {
                this.geolocationPosition = position;
                this.verifyCode(this.scanCode);
            },
            error => {
                this._toastMessageService.alert('error','Location Permission needed.');
                /*switch (error.code) {
                    case 1:
                        this._toastMessageService.alert('error','Location Permission needed.');
                        break;
                    case 2:
                        this._toastMessageService.alert('error','Location Position Unavailable');
                        break;
                    case 3:
                        this._toastMessageService.alert('error','Timeout to give location permission');
                        break;
                    default: 
                        this._toastMessageService.alert('error','Timeout');
                        break;
                }*/
            }
        );
    };
  }
 
  verifyCode(code): void {
    if(!this.geolocationPosition) {
      this._toastMessageService.alert("error","Please allow location permission to proceed and refresh the page");
      return;
    }
    let params = {
      location : {
        latitude: this.geolocationPosition.coords.latitude,
        longitude: this.geolocationPosition.coords.longitude,
      },
      code:code
    }
    this.loading = true;    
    NavbarService.getInstance(this.http).verifyQRCode(params).subscribe(res => {
      if(res && res.status == 0) {
        NavbarService.getInstance(this.http).setQRCodeData(res.result.shop_info);
        this.router.navigateByUrl('/login');
      } else {
        let errorMessage = res.message ? res.message : "Internal server error";
        this._toastMessageService.alert("error",errorMessage);
        this.router.navigate(['/qrcode-scanner']);
      }
      this.loading = false;    
    }, err => {
      let errorMessage = (err.error && err.error.message) ? err.error.message : "Internal server error.";
      this._toastMessageService.alert("error",errorMessage);
      this.loading = false;    
      this.router.navigate(['/qrcode-scanner']);    
    });    
  }

}
