import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainItem } from '../../interfaces/main-item';
import { MainSubItem } from '../../interfaces/main-sub-item';
import { MainSubItemOption } from '../../interfaces/main-sub-item-option';
import { MainSubItemOptionVariant } from '../../interfaces/main-sub-item-option-variant';
import { ORDER_DATA } from '../../DB/cart-items';
import { CartService } from '../../services/cart.service';
import { NavbarService } from '../../services/navbar.service';
import { environment } from '../../../environments/environment';
import { ToastMessageService } from '../../services/toast-message.service';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../../services/shared.service';


declare let Razorpay: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {

  order_details = ORDER_DATA;
  items: MainItem[] = ORDER_DATA.CART_ITEMS;

  addNote: boolean[] = [];

  addSpiceHot: boolean;
  addFastDelivery: boolean;
  currencyCode:any = "INR";
  loading: boolean = false;
  shop_code: any = localStorage.getItem("shop_code");  
  qrData: any = localStorage.getItem("QRD") || {};
  discountCode:any;

  shopTaxInfo:any = JSON.parse(localStorage.getItem("shop_tax")) || null;

  COD_PAYMENT = SharedService.COD_PAYMENT;
  ONLINE_PAYMENT = SharedService.ONLINE_PAYMENT;
  paymentType;
  isCODAvailable = false;
  isOnlinePaymentAvailable = false;

  constructor(private navbarService: NavbarService,
              private router: Router,
              private http: HttpClient,
              private _toastMessageService: ToastMessageService,
              private _cartService: CartService) { }

  ngOnInit() {
    if (!this.shop_code || !NavbarService.getInstance(null).isSessionValid()) {
      this.router.navigateByUrl('/');
      return;
    }  
    
    // change item id to avoid duplicate (only for local using for example)
    this.items.forEach((item: MainItem) => {
      item.item_code = item.item_code
    });

    if(this.shopTaxInfo) {      
      this.isCODAvailable = (this.shopTaxInfo.msite_payment_types && this.shopTaxInfo.msite_payment_types.indexOf(this.COD_PAYMENT) != -1) ? true : false;
      if(!this.shopTaxInfo.msite_payment_types || this.shopTaxInfo.msite_payment_types.length == 0 || (this.shopTaxInfo.msite_payment_types && this.shopTaxInfo.msite_payment_types.indexOf(this.ONLINE_PAYMENT) != -1)) {
        this.isOnlinePaymentAvailable = true;
      } else {
        this.isOnlinePaymentAvailable = false;
      }
    }

    if(this.isOnlinePaymentAvailable) {
      this.paymentType = SharedService.ONLINE_PAYMENT;
    } else if(this.isCODAvailable) {
      this.paymentType = this.COD_PAYMENT;
    } else {
      this.paymentType = SharedService.ONLINE_PAYMENT;
    }


    this.currencyCode =  localStorage.getItem("currency_code") || "INR";
  }

  getOptionInfo(subItem: MainSubItem): string {
    
    let optionInfo: string = '';
    
    subItem.options.forEach((option: MainSubItemOption) => {
      option.variants.forEach((variant: MainSubItemOptionVariant) => {
        if(variant.selected)
          optionInfo += option.item_name + '/' + variant.item_name + ', ';
      });
    });

    return optionInfo;
  }

  itemQuantityChanged(item: MainItem, item_count: number,index): void {
    item.item_count = item_count;
    
    if(!item.item_count) {
      this._cartService.removeItemFromCart(item,index);
    } else {
      this._cartService.calculateOrderTotal();
    }
  }


  selectOptionVariant(option: MainSubItemOption, variant: MainSubItemOptionVariant): void {
    /*option.variants.forEach((vr: MainSubItemOptionVariant) => {
      vr.selected = false;
    });*/
    variant.selected = !variant.selected;
    variant.item_count = (variant.selected) ? 1 : 0;        
    this._cartService.calculateOrderTotal();
  }

  saveNote(index: any, event: KeyboardEvent): void {
    if(event.keyCode === 13)
      this.addNote[index] = false;
  }

  saveOrderNote(event: KeyboardEvent): void {
    if(event.keyCode === 13)
      this.addNote["order_note"] = false;
  }

  getItemPrice(item) {
    let itemPrice:number = 0;
    if(item.item_count > 0) {
      itemPrice += Number(item.item_count * item.unit_price)
      if(Array.isArray(item.options) && item.options.length>0) {
        item.options.forEach(op => {
          if(Array.isArray(op.variants) && op.variants.length>0) {
            op.variants.forEach(vItem => {
              if(vItem.selected) {
                itemPrice += Number(vItem.unit_price);
              }
            });
          }
        })
      }
    } else {
      itemPrice = Number(item.unit_price);
    }

    return itemPrice;    
  }

  onCheckout() {    
    let user_data = JSON.parse(localStorage.getItem('UMD')) || {};            
    let tempOrderData = JSON.parse(JSON.stringify(this.order_details));    
    if(!user_data.auth_token) {
      this._toastMessageService.alert("error","Unauthorized access. Please logout and login again");
      return;
    } else if(tempOrderData.ORDER_TOTAL<=0) {
      this._toastMessageService.alert("error","Please add order of more then 0 amount");
      return;
    } else if(tempOrderData.CART_ITEMS.length == 0) {
      this._toastMessageService.alert("error","Please add atleast one item in a cart");
      return;
    } else if(!this.paymentType) {
      return this._toastMessageService.alert("error","No payment option available"); 
    }

    this.loading = true;
    this.verifyShipping(tempOrderData).then(vsResult => {
      if(vsResult) {
        this.placeOrder(tempOrderData,user_data).then((poResult:any) => {
          if(poResult) {
            let orderParams = poResult;
            if(this.paymentType == this.COD_PAYMENT) {
              this.makePayment(orderParams).then((mpResult:any) => {
                this._toastMessageService.alert("success","Order placed successfully.");
                this.loading = false;
                this._cartService.resetOrder();
                this.router.navigateByUrl('/order-list');                  
              });
            } else {
              this.razorpayCheckout(poResult,user_data).then((razorpay_payment_id:any) => {
                if(razorpay_payment_id) {
                  orderParams.payment_id = razorpay_payment_id;
                  this.savePaymentGateway(orderParams).then((spgResult:any) => {
                    if(spgResult) {
                      this.makePayment(orderParams).then((mpResult:any) => {
                        this._toastMessageService.alert("success","Order placed successfully.");
                        this.loading = false;
                        this._cartService.resetOrder();
                        this.router.navigateByUrl('/order-list');                  
                      });
                    } else {
                      this.loading = false;
                    }
                  });
                } else {
                  this.loading = false;
                }
              });
            }  
            
          } else {
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }
    });
  }

  verifyShipping(tempOrderData) {
    return new Promise((resolve,reject) => {
      let params = {
        shop_code:this.shop_code,
        shipping_type:NavbarService.PICK_UP,
        order_value:tempOrderData.ORDER_TOTAL
      }
      NavbarService.getInstance(this.http).verifyShipping(params).subscribe(res => {
        if(res && res.status == 0) {          
          return resolve(true)
        } else {        
          let message = (res.error && res.error.message) ? res.error.message : (res.message) ? res.message : "Error while checking shipping possibility.please try again."
          this._toastMessageService.alert("error",message);
          return resolve(null);
        }
      }, err => {      
        this._toastMessageService.alert("error","Error while possibility.please try again.");
        return resolve(null);
      });
    });
  }

  placeOrder(currentOrderData,user_data) {
    return new Promise((resolve,reject) => {
      let shopTaxInfo:any = JSON.parse(localStorage.getItem("shop_tax")) || null;
      this.qrData = JSON.parse(this.qrData);

      let order_tax_breakup = [];
      let order_applied_discount_info = [];
      let order_tax_rate = 0;
      let order_tax_value = 0;
      let order_tax_type= "main";
      let order_cart_items = [];
      if(shopTaxInfo && !shopTaxInfo.item_wise_tax) {
        order_tax_breakup = currentOrderData.ORDER_TAX_DETAILS;

        if(Array.isArray(order_tax_breakup)) {
          order_tax_breakup.forEach(taxb => {
            if(taxb.rate) { order_tax_rate += taxb.rate; }
            if(taxb.value) { order_tax_value += taxb.value; }
          })
        }

        if(Array.isArray(shopTaxInfo.extra_tax_components) && 
          shopTaxInfo.extra_tax_components[0] && shopTaxInfo.extra_tax_components[0]["IND"]){
          order_tax_type = shopTaxInfo.extra_tax_components[0]["IND"].main_tax_name;
        }
      }

      order_cart_items = this._cartService.getOrderCartItems();
      order_cart_items.forEach(item => {
        item.tax_rate = 0;
        item.tax_value = 0;
        item.tax_type = "main";
        if(shopTaxInfo && shopTaxInfo.item_wise_tax) {
          if(Array.isArray(item.tax_breakup)) {
            item.tax_breakup.forEach(taxb => {
              if(taxb.rate) { item.tax_rate += taxb.rate; }
              if(taxb.value) { item.tax_value += taxb.value; }
              if(taxb.type) { item.tax_type = taxb.type }
            })
          }            
        } else {
          item.tax_breakup = [];
        }        
      });

      currentOrderData.APPLIED_DISCOUNT.forEach(di => {
        order_applied_discount_info.push({
          claimed_value:di.value,
          discount_code:di.name,
          type:di.type,
          is_max_discount_val:di.is_max_discount_val
        });
      });      

      let orderParams:any = {
        applied_discount_info: order_applied_discount_info,
        delivery_date: new Date(),
        createdAt: new Date(),
        shipping_type: "ST0001",
        auth_token: user_data.auth_token,
        cart_items: order_cart_items,
        kot_items: [],
        payment_type: this.paymentType == this.COD_PAYMENT ? this.COD_PAYMENT : this.paymentType,
        shop_code: this.shop_code,
        shop_section:this.qrData.section ? this.qrData.section: "",
        table_number: [((this.qrData && this.qrData.table_number && this.qrData.table_number != -1) ? this.qrData.table_number : 0)],
        table_covers: 1,
        separate_bill_for_tax_classes: 0,
        extra_charges: currentOrderData.EXTRA_CHARGES,
        tax_breakup: order_tax_breakup,
        tax_rate: order_tax_rate,
        tax_value: order_tax_value,
        cancel_order: 0,
        non_chargeable: 0,
        client_reference_code: "NA",
        valued_at: currentOrderData.ORDER_TOTAL,
        sub_total: currentOrderData.ORDER_SUB_TOTAL,
        rounding_value: currentOrderData.ROUNDING_VALUE,
        service_tax:currentOrderData.ORDER_TOTAL_TAX,
        total_other_discount_amount:currentOrderData.ORDER_TOTAL_DISCOUNT,
        note:currentOrderData.NOTE,
        order_from: 'msite'
      }
      //console.log('orderParams:', orderParams);
      NavbarService.getInstance(this.http).placeOrder(orderParams).subscribe(res => {
        if(res && res.status == 0) {          
          orderParams.order_id = res.result.order_detail.order_detail.order_id;
          orderParams.razorpay_order_id = res.result.order_detail.razorpay_order_id;
          if(!orderParams.razorpay_order_id) {
            this._toastMessageService.alert("error",(res.message) ? res.message : "Error while connect payment gateway.please try again.");
            return resolve(null);
          } else {
            return resolve(orderParams);
          }
        } else {        
          this._toastMessageService.alert("error",(res.message) ? res.message : "Error while place order.please try again.");
          return resolve(null);
        }
      }, err => {      
        this._toastMessageService.alert("error","Error while place order.please try again.");
        return resolve(null);
      });
    })
  }

  razorpayCheckout(orderParams,user_data):any {
    let self = this;
    return new Promise((resolve,reject) => {
      let user_data = JSON.parse(localStorage.getItem('UMD')) || {};      
      let amount = orderParams.valued_at*100;
      let options = {
        'key': environment.RAZORPAY_KEY,
        'amount': amount.toFixed(0),
        "currency": "INR",
        'name': 'Rise',        
        'image': 'https://api.onegreendiary.com/images/favicon.png',
        'description': orderParams.order_id,       
        "order_id":orderParams.razorpay_order_id,         
        'prefill': {
            'name': (user_data && user_data.consumer_name) ? user_data.consumer_name : 'Guest',
            'email': (user_data && user_data.user_email) ? user_data.user_email : '',
            'contact': (user_data && user_data.user_mobile) ? user_data.user_mobile : ''
        },        
        'theme': {
            'color': '#F37254'
        },
        'handler': function(response) {   
          resolve(response.razorpay_payment_id)
        },
        "modal": {
          "ondismiss": function(){
            resolve(false)
          }
        }
      };

      let rzp1 = new Razorpay(options);
      rzp1.open();
    })
  }

  savePaymentGateway(orderParams) {
    return new Promise((resolve,reject) => {
      let aParams = {
        order_id: orderParams.order_id,
        payment_id: orderParams.payment_id,
        shop_code: orderParams.shop_code,
        amount: (orderParams.valued_at*100)
      };
      NavbarService.getInstance(this.http).savePaymentGatewayDetails(aParams).subscribe(res => {
        if(res && res.status == 0) {
          return resolve(true)
        } else {        
          this._toastMessageService.alert("error","error while verification of payment.please try again.");
          return resolve(false);
        }
      }, err => {      
        this._toastMessageService.alert("error","Internal server error.");
        return resolve(false);
      });
    })
  }

  makePayment(orderParams) {
    return new Promise((resolve,reject) => {
      let mParams = {
        "order_id": orderParams.order_id,
        "payment_type": orderParams.payment_type,
        "split_payment": {},
        "split_payment_details": {},
        "shop_code": orderParams.shop_code,
        "sub_total": orderParams.sub_total,
        "paid_amount": orderParams.valued_at,
        "card_amount": 0,
        "discount_amount":0,
        "total_other_discount_amount":orderParams.total_other_discount_amount,
        "cash_discount_amount":0,
        "rewards_amount":0,
        "rewards_pt":0,
        "diary_amount":0,
        "offer_amount":0,
        "rounding_value":orderParams.rounding_value,
        /*"offer_code":"",*/
        "service_tax":orderParams.service_tax,
        "service_charge":0
      }

      mParams["split_payment"][orderParams.payment_type] = orderParams.valued_at;
      mParams["split_payment_details"][orderParams.payment_type] = {
        "name": (orderParams.payment_type == this.COD_PAYMENT ? "COD" : "Online") + " Payment",
        "amount": orderParams.valued_at,
        "transaction_id": orderParams.payment_id,
        "gateway":"razorpay",
        "comment": "msite "+(orderParams.payment_type == this.COD_PAYMENT ? "cod" : "online")+" payment",
        "payment_sub_class_info": []
      };

      NavbarService.getInstance(this.http).makePayment(mParams).subscribe(res => {
        if(res && res.status == 0) {
          return resolve(true)
        } else {        
          this._toastMessageService.alert("error","error while completing payment.please try again.");
          return resolve(false);
        }
      }, err => {      
        this._toastMessageService.alert("error","Internal server error.");
        return resolve(false);
      });
    })
  }

  verifyDiscountCode() {
    if(!this.discountCode) {
      this._toastMessageService.alert("error","Please enter valid discount code.");
      return
    }

    let params = {
      discount_code: JSON.parse(JSON.stringify(this.discountCode.toLowerCase())),
      shop_code: this.shop_code,
      cart_items: this._cartService.getOrderCartItems(),
      sub_total: this.order_details.ORDER_SUB_TOTAL,
      shipping_type: NavbarService.PICK_UP      
    }

    this.discountCode = "";
    this.loading = true;
    NavbarService.getInstance(this.http).applyDiscount(params).subscribe((res:any) => {
      this.loading = false;

      if(res.status == 0)  {
        if (res.result.data && res.result.data.status_code == 0) {
          let isApplied = false;
          for(var i=0,adLen=this.order_details.APPLIED_DISCOUNT.length;i<adLen;i++) {
            if(this.order_details.APPLIED_DISCOUNT[i].name == res.result.discount_info.discount_code) {
              isApplied = true;
              break;
            }
          }

          if(isApplied) {
            this._toastMessageService.alert("error", "discount already applied.");
            return;
          }

          res.result.discount_info.max_discount = res.result.data.max_discount;
          res.result.discount_info.is_external = true;
          this.order_details.MANUAL_APPLIED_DISCOUNT_DETAIL.push(res.result.discount_info);
          this._cartService.calculateOrderTotal();
        }else if(res.result.data && res.result.data.status_code == 1) {
          this._toastMessageService.alert("error", "product condition not matched");
        }else if(res.result.data && res.result.data.status_code == 2){
          this._toastMessageService.alert("error", "maximum/minimum cart value not matched");
        }else if(res.result.data && res.result.data.status_code == 3){
          this._toastMessageService.alert("error", "user not avail for this offer");
        }else if(res.result.data && res.result.data.status_code == 4){
          this._toastMessageService.alert("error", 'user use this coupon maximum time');
        }else if(res.result.data && res.result.data.status_code == 5){
          this._toastMessageService.alert("error", 'user use coupon maximum value');
        }else if(res.result.data && res.result.data.status_code == 6){
          this._toastMessageService.alert("error", 'Discount is not applied due to some reason');
        }else if(res.result.data && res.result.data.status_code == 8){
          this._toastMessageService.alert("error", 'discount expired');
        }else if(res.result.data && res.result.data.status_code == 9){
          this._toastMessageService.alert("error", 'usage time not match');
        }else if(res.result.data && res.result.data.status_code == 10){
          this._toastMessageService.alert("error", 'required item not found');
        }else if(res.result.data && res.result.data.status_code == 11){
          this._toastMessageService.alert("error", 'shipping type not valid for discount');
        }else if(res.result.data && res.result.data.status_code == 12){
          this._toastMessageService.alert("error", 'either minimum item not purchased or purchased item count is more then maximum item purchase');
        }else if(res.result.data && res.result.data.status_code == 13){
          this._toastMessageService.alert("error", 'condition not match with buy and get items');
        }else if(res.result.data && res.result.data.status_code == 14){
          this._toastMessageService.alert("error", 'either membership not purchased or expired');
        }else if(res.result.data && res.result.data.status_code == 15){
          this._toastMessageService.alert("error", 'last order value not matched with discount minimum value');
        }else if(res.result.data && res.result.data.status_code == 16){
          this._toastMessageService.alert("error", 'discount order id already used in other order to get discount');
        }else if(res.result.data && typeof res.result.data == "string"){
          this._toastMessageService.alert("error", res.result.data);
        }
      }else{
        this._toastMessageService.alert("error", 'Something went wrong');
      }
    }, err => {      
      this._toastMessageService.alert("error","Internal server error.");
      this.loading = false;      
    });
  }

  onChangePaymentType(paymentType) {
    this.paymentType = paymentType;
    this.order_details.PAYMENT_TYPE = paymentType;    
    this._cartService.calculateOrderTotal();     
  }
}
