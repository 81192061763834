import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarService } from '../../services/navbar.service';
import { HttpClient } from '@angular/common/http';
import { ToastMessageService } from '../../services/toast-message.service';
import { StateGuardService } from '../../services/state-guard.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {

  isOTPSend: boolean;
  loading: boolean = false;
  userData: any = {
    consumer_name:"",
    mobile_no:""
  }

  shop_code: any = localStorage.getItem("shop_code");
  verification_code : any = "";
  constructor(private router: Router,
              private http: HttpClient,
              private navbarService: NavbarService,
              private _toastMessageService: ToastMessageService,
              private _stateGuardService: StateGuardService
              ) { }

  ngOnInit() {
    var isVerified:any = localStorage.getItem("IS_VERIFIED");

    if(!this.shop_code) {
      this.router.navigateByUrl('/');
      return
    }

    if (isVerified && isVerified != "false") {
      NavbarService.getInstance(null).setSession();
      this.router.navigateByUrl('/main');
      return;
    } 
  }

  sendOTP(): void {   

    if(!this.userData.mobile_no || !(/^\d{10}$/.test(this.userData.mobile_no))) {
      this._toastMessageService.alert("error","please enter 10 digit valid mobile number");
      return;
    }

    this.loading = true;
    NavbarService.getInstance(this.http).login({mobile_no:this.userData.mobile_no,consumer_name:this.userData.consumer_name,request_from:"msite",shop_code:this.shop_code}).subscribe(res => {
      if(res && res.status == 0) {
        let result = res.result;
        result.mobile_no = this.userData.mobile_no;
        result.consumer_name = this.userData.consumer_name;
        NavbarService.getInstance(null).setUserData(result);
        NavbarService.getInstance(null).setVerifiedFlag(false);
        this.isOTPSend = true;
      } else {
        let errorMessage = res.message ? res.message : "Internal server error";
        this._toastMessageService.alert("error",errorMessage);
      }

      this.loading = false;
    }, err => {
      let errorMessage = (err.error && err.error.message) ? err.error.message : "Internal server error.";
      this._toastMessageService.alert("error",errorMessage)
      this.loading = false;
    });
  }

  resendOTP(): void {
    this.loading = true;
    NavbarService.getInstance(this.http).resendOTP(this.shop_code).subscribe(res => {
      if(res && res.status == 0) {
        this._toastMessageService.alert("success","otp resent successfully");
        this.isOTPSend = true;
      } else {
        let errorMessage = res.message ? res.message : "Internal server error";
        this._toastMessageService.alert("error",errorMessage);
      }
      this.loading = false;
    }, err => {
      console.log(err)
      let errorMessage = (err.error && err.error.message) ? err.error.message : "Internal server error.";
      this._toastMessageService.alert("error",errorMessage)
      this.loading = false;
    });
  }

  startOrdering(): void {
    if(!this.verification_code) {
      this._toastMessageService.alert("error","please enter verification code");
      return;
    }

    this.loading = true;    
    NavbarService.getInstance(this.http).verifyOTP({verification_code:this.verification_code}).subscribe(res => {
      if(res && res.status == 0) {
        NavbarService.getInstance(null).setVerifiedFlag(true);        
        this._stateGuardService.entrust("isLoggedIn",true);
        this.router.navigateByUrl('/main');
      } else {
        let errorMessage = res.message ? res.message : "Internal server error";
        this._toastMessageService.alert("error",errorMessage);
      }

      this.loading = false;    
    }, err => {
      let errorMessage = (err.error && err.error.message) ? err.error.message : "Internal server error.";
      this._toastMessageService.alert("error",errorMessage)
      this.loading = false;
    });    
  }

}
