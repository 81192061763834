import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../../services/navbar.service';
import { ORDER_DATA } from '../../DB/cart-items';
import { Router, NavigationEnd } from '@angular/router';
import { MessageSubscriber } from "../../services/message-subscriber.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  order_details = ORDER_DATA;  

  consumer_name:any = "";
  isLoggedIn: boolean = false;
  currencyCode:any = "INR";  
  currentPage: any = "main";
  shop_name: any = "mPos";
  isSearchClicked: boolean = false;
  searchText:any = "";
  constructor(public _navbarService: NavbarService,private router: Router,private _messageSubscriber:MessageSubscriber) { 
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {  
        var tempSplit = event.url.split("/");
        if(tempSplit && (tempSplit[1] || tempSplit[1] == "")) {
          this.currentPage = tempSplit[1];            
          this.onSearchDone();
        } else {
          this.currentPage = "main";            
        }          
      };
    });
  }

  ngOnInit() {}

  ngDoCheck() {
    this.consumer_name = NavbarService.getInstance(null).consumer_name;    
    this.shop_name = NavbarService.getInstance(null).shop_name;    
    this.isLoggedIn = NavbarService.getInstance(null).isLoggedIn;
  }

  showSideMenu() {
    var el = document.getElementById('menu_wrapper_id');
    if(el) {
      el.className += ' menu-side-nav-width';  
    }
  }

  redirectToPage() {
    if(this.currentPage != 'order-list') {
      this.router.navigateByUrl('/order-list');      
    } else {
      this.router.navigateByUrl('/main');
    }
  }

  

  onClickSearch() {
    this.isSearchClicked = true;
    this.searchText = "";
  }

  onSearchDone() {
    this.isSearchClicked = false;
    this.searchText = ""; 
    this.sendSearchData("");
  }

  sendSearchData(event:any) {
    this._messageSubscriber.sendMenuSearchText(this.searchText);
  }

}
